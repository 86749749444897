import MicroModal from 'micromodal'

document.addEventListener('DOMContentLoaded', () => {
  var navTrigger = document.getElementById('header-nav')
  if (navTrigger) {
    navTrigger.addEventListener('click', () => {
      const onClose = function() {
        document.querySelector('.header__menu-icon-open').classList.remove('hidden')
        document.querySelector('.header__menu-icon-close').classList.add('hidden')
        document.querySelector('.header').classList.remove('header__with-nav')
      }
      const isOpen = !document.querySelector('.header').classList.contains('header__with-nav')
      if (isOpen) {
        MicroModal.show('menu', {
          onClose: onClose,
          disableScroll: true,
          openClass: 'menu--open',
          awaitOpenAnimation: true,
        })
        document.querySelector('.header__menu-icon-open').classList.add('hidden')
        document.querySelector('.header__menu-icon-close').classList.remove('hidden')
        document.querySelector('.header').classList.add('header__with-nav')
      } else {
        MicroModal.close('menu')
        onClose()
      }
    })
  }
})
