import Swiper, { Autoplay } from 'swiper'

Swiper.use([Autoplay]);

document.addEventListener('DOMContentLoaded', () => {
  const reviewSwiper = new Swiper('.google-reviews', {
    loop: true,
    loopAdditionalSlides: 1,
    slidesPerView: 'auto',
    grabCursor: true,
    centeredSlides: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: true,
    },
  })

  const logoSwiper = new Swiper('.car-logos', {
    loop: true,
    loopAdditionalSlides: 1,
    slidesPerView: 'auto',
    speed: 2000,
    allowTouchMove: false,
    autoplay: {
      delay: 0,
    },
  })
})
